import request from '@/utils/request'

// 获取订单类型
export function queryOrderType () {
  return request({
    url: '/order/queryOrderType',
    headers: {
      isToken: true
    },
    method: 'get',
    timeout: 20000
  })
}
// 充值成为会员
export function memberOrder (data) {
  return request({
    url: `/order/memberOrder?orderTypeId=${data.orderTypeId}&payType=${data.payType}&payMode=${data.payMode}`,
    headers: {
      isToken: true
    },
    method: 'get',
    timeout: 20000,
    data: data
  })
}
// 查询订单是否支付成功/chatapp/order/payOrderFinshQuery
export function payOrderFinishQuery (orderId) {
  return request({
    url: `/order/payOrderFinshQuery?orderId=${orderId}`,
    headers: {
      isToken: true
    },
    method: 'get',
    timeout: 20000
  })
}
